var render = function render(){var _vm=this,_c=_vm._self._c;return _c('hk-card',[_c('div',{staticClass:"card-header",attrs:{"slot":"header"},slot:"header"},[_c('h1',[_c('i',{staticClass:"fas fa-tools mr-2",attrs:{"aria-hidden":"true"}}),_vm._v(" D&D 5e Tools")])]),_c('div',{staticClass:"card-body"},[(_vm.user)?_c('SignedIn'):_vm._e(),_c('h2',[_vm._v("\n\t\t\tOnline tools to enhance and simplify every aspect of your tabletop role-playing experience.\n\t\t")]),_c('p',[_vm._v("\n\t\t\tDesigned with the Dungeon Master in mind, our suite of tools is a comprehensive resource to\n\t\t\tstreamline and enrich every facet of your storytelling journey. From managing combat\n\t\t\tencounters with our initiative tracker and creating unique monsters to seamlessly guiding\n\t\t\tthe narrative with a digital DM screen, our tools provide the essential support needed for\n\t\t\tDungeon Masters to create immersive and dynamic adventures. Dive into a realm where our\n\t\t\ttools serve as your digital assistant, helping you master the art of storytelling and\n\t\t\tdeliver unparalleled experiences to your players.\n\t\t")]),_vm._l((_vm.tools),function(group,i){return [_c('h2',{key:`title-${i}`,class:{ 'mt-5': i > 0 }},[_vm._v(_vm._s(group.title))]),_c('div',{key:i,staticClass:"row q-col-gutter-md",class:{ 'pb-2': i === 0 }},_vm._l((group.tools),function(tool,key){return _c('div',{key:key,staticClass:"col-12 col-sm-6 col-md-4"},[_c('router-link',{attrs:{"to":tool.url}},[_c('hk-card',{staticClass:"full-height tool"},[_c('div',{staticClass:"card-image",style:([
									tool.image
										? {
												backgroundImage: `url(${require(`src/assets/_img/atmosphere/medium/${tool.image}`)})`,
										  }
										: '',
								]),attrs:{"slot":"image"},slot:"image"},[_c('i',{class:tool.icon,attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"card-header",attrs:{"slot":"header"},slot:"header"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(tool.title)+"\n\t\t\t\t\t\t\t")]),_c('div',{staticClass:"card-body"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(tool.description)+"\n\t\t\t\t\t\t\t")]),_c('div',{staticClass:"card-footer",attrs:{"slot":"footer"},slot:"footer"},[(tool.under_development)?_c('div',{staticClass:"red full-width text-center"},[_vm._v("\n\t\t\t\t\t\t\t\t\tUnder development\n\t\t\t\t\t\t\t\t")]):_c('button',{staticClass:"btn btn-block"},[_vm._v("Use "+_vm._s(tool.title))])])])],1)],1)}),0)]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }